<template>
    <div>
        <v-row class="mx-0 mb-3" no-gutters v-if="type === 'item'">
            <v-col cols="12">
                <v-row align="center">
                    <v-col class="pt-0">
                        <v-skeleton-loader
                                type="heading"
                                min-width="300"
                                max-width="500"
                        />
                    </v-col>
                    <v-col class="pr-0 pt-0" cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg fill-width"
                                max-height="30"
                                min-width="137"
                                
                        />
                    </v-col>
                    <v-col cols="auto" class="pt-0">
                        <v-skeleton-loader
                                type="button"
                                class="secondary rounded-lg actions"
                                max-height="30"
                        />
                    </v-col>
                </v-row>
            </v-col>
            
            <v-col class="elevation-2 rounded-lg cell px-3">
                <v-row>
                    <v-col cols="12" sm="6" lg="4">
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" lg="4">
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" lg="4">
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mx-0" no-gutters v-if="type === 'item-creation'">
            <v-col class="elevation-2 rounded-lg cell pa-1">
                <v-row no-gutters class="pa-3">
                    <v-col cols="6" class="pt-3 pb-4">
                        <v-skeleton-loader
                                type="heading"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                        <v-skeleton-loader
                                type="list-item-two-line"
                        />
                    </v-col>
                </v-row>
                <v-row class="pa-4 mx-0" no-gutters>
                    <v-col>
                        <v-skeleton-loader
                                type="card"
                                class="rounded-lg"
                                max-height="30"
                                min-width="100px"
                        />
                    </v-col>
                    <v-col class="pl-4">
                        <v-skeleton-loader
                                type="card"
                                class="rounded-lg"
                                max-height="30"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mx-0 my-0 rounded-lg cell elevation-2" no-gutters v-if="type === 'table' || type === 'item' ">
            <v-col cols="12">
                <v-row align="center" class="mx-0">
                    <v-col cols="auto" class="pr-0">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg actions"
                                max-height="30"
                                max-width="110"
                        />
                    </v-col>
                    <v-col cols="auto">
                       <v-divider vertical />
                    </v-col>
                     <v-col cols="auto" class="pr-0 pl-0">
                        <div class="rounded-lg">
                          
                           <v-skeleton-loader
                                    tile
                                    type="button@3"
                                    class="rounded-lg d-flex"
                                    max-height="30"
                                    max-width="137"
                            />
                            
                        </div>
                        
                    </v-col>
                    <v-spacer />
                    <v-col class="pr-0" cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg fill-width"
                                max-height="30"
                                min-width="137"
                                
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="secondary rounded-lg actions"
                                max-height="30"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="mx-0" align="center">
                    <v-col cols="auto">
                        <v-skeleton-loader
                                type="text"
                                min-width="18"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="chip"
                                class="d-flex fill-width"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="chip"
                                class="d-flex fill-width"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="chip"
                                class="d-flex fill-width"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="chip"
                                class="d-flex fill-width"
                        />
                    </v-col>
                    <v-col cols="auto" >
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg py-0"
                                max-height="32"
                                max-width="32"
                        />
                    </v-col>
                    <v-col cols="auto" class="py-0 pl-0">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg"
                                max-height="32"
                                max-width="32"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-row class="mx-0" v-for="n in 5" :key="n" align="center" :class="n % 2 !== 0 ? 'cell2':''">
                    <v-col>
                        <v-skeleton-loader
                                type="sentences"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="sentences"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="sentences"
                        />
                    </v-col>
                    <v-col>
                        <v-skeleton-loader
                                type="sentences"
                        />
                    </v-col>
                    <v-col cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg ml-auto"
                                max-height="32"
                                max-width="32"
                        />
                    </v-col>
                    <v-col cols="auto" class="py-0 pl-0">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg ml-auto"
                                max-height="32"
                                max-width="32"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="mx-0" align="center">
                    <v-col>
                        <v-skeleton-loader
                                type="heading"
                        />
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg"
                                max-height="32"
                                max-width="32"
                        />
                    </v-col>
                    <v-col cols="auto" class="pl-0">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg"
                                max-height="32"
                                max-width="32"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mx-0 my-0 mb-3 rounded-lg cell" no-gutters v-if="type === 'calendar'">
            <v-col cols="12">
                <v-row align="center" class="mx-0">
                    <v-col cols="auto" class="pr-0">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg actions"
                                max-height="30"
                                max-width="44"
                        />
                    </v-col>
                    <v-spacer />
                   
                    <v-col cols="auto" class="pa-0">
                        <div class="rounded-lg">
                          
                           <v-skeleton-loader
                                type="button"
                                class="rounded-circle actions"
                                max-height="40"
                                max-width="40"
                            />
                        </div>
                    </v-col>
                    <v-col cols="auto" class="pa-0">
                        <div class="rounded-lg">
                          
                           <v-skeleton-loader
                                type="button"
                                class="rounded-circle actions"
                                max-height="40"
                                max-width="40"
                            />
                        </div>
                    </v-col>

                    <v-spacer />

                    <!-- <v-col cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg fill-width"
                                max-height="28"
                                min-width="75"
                                
                        />
                    </v-col> -->
                    <v-col cols="auto">
                        <v-skeleton-loader
                                type="button"
                                class="rounded-lg fill-width "
                                max-height="28"
                                min-width="84"
                                
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row class="mx-0" align="center">
                    <v-col class="pa-0">
                        <v-skeleton-loader
                                tile
                                type="button@7"
                                class=" fill d-flex stroke"
                                height="113"
                        >
                        </v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row class="mx-0" align="center" v-for="y in 4" :key="y">
                    <v-col class="pa-0">
                        <v-skeleton-loader
                                tile
                                type="button@7"
                                class=" fill d-flex stroke"
                                height="96.5"
                        >
    
                        </v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row class="mx-0 mt-3" no-gutters v-if="type === 'item'">
            <v-col
                cols="6"
                class="pr-3"
            >
                <v-skeleton-loader 
                    type="card-heading, table-tbody"
                    class="elevation-2 rounded-lg cell"
                    height="419px"
                ></v-skeleton-loader>

            </v-col>
            <v-col
                cols="6"
                class="px-3"
            >   
                <v-row class="elevation-2 rounded-lg cell">
                    <v-col cols="12"  class="pa-0">
                            <v-skeleton-loader 
                                type="card-heading"
                                height="50px"
                            ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                            <v-skeleton-loader 
                                type="paragraph"
                                max-width="50%"
                                
                            ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" align="right">
                            <v-skeleton-loader 
                                type="paragraph"
                                max-width="50%"
                            ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                            <v-skeleton-loader 
                                type="paragraph"
                                max-width="50%"
                                
                            ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" align="right">
                            <v-skeleton-loader 
                                type="paragraph"
                                max-width="50%"
                            ></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                            <v-skeleton-loader 
                                type="chip"
                                class="fill-width"
                            ></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
	export default {
		name: "ComponentLoader",
        props: ['type']
	}
</script>

<style >
    .fill-width > div{
        width: 100%
    }
  
    .fill > div {
        width: 100%;
        height:  100%;
        
    }
    .stroke > div {
        border: solid 1px var(--v-cell2-base);
        box-sizing: border-box;
    }
</style>
